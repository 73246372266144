<template>
  <div class="about" data-view>
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>

    <Morghing></Morghing>
  </div>
</template>

<script>
import Morghing from '@/components/MainPage/Morghing.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'About',

  components: {
    Morghing,
    Header,
  },
};
</script>
